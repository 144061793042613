<template>
  <div class="login">
    <div class="login-back"></div>
    <div class="bb"></div>
    <div class="login-title">
      <h2>商厨荟</h2>
    </div>
    <div class="login-input">
      <div class="login-username">
        <input v-model="username" placeholder="用户名" class="name-b" />
      </div>

      <div class="login-password">
        <input v-model="password" placeholder="密码" type="password" class="name-b" />
      </div>

      <a @click="btnlogin" class="btn-login">登录</a>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { postLogin } from 'network/login'

export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapState(['userlist']),
    userlist: {
      get() {
        return this.$store.state.userlist
      },
      set(value) {
        return (this.$store.state.userlist = value)
      }
    }
  },
  methods: {
    ...mapMutations(['setToken']),
    btnlogin() {
      postLogin(this.username, this.password).then((res) => {
        if (!res.data.result) {
          this.$message('用户名密码错误')
        } else if (res.data.result.token != null) {
          // localStorage.setItem('token',res.data.result.token);
          localStorage.setItem('userInfo', JSON.stringify(res.data.result))
          localStorage.setItem('userId', res.data.result.user_id)
          this.setToken({ token: res.data.result.token }) //store中的为token赋值方法
          this.userlist = res.data.result
          this.$router.push('/home').catch(() => {})
        } else {
          this.$message('用户名密码错误')
        }
      })
    }
  }
}
</script>

<style scoped>
.login {
  position: relative;
  z-index: 999;
  height: 100vh;
  background-color: #7dbfff;
}
.login-back {
  width: 100%;
  overflow: hidden;
  height: 100vh;
}
#video {
  height: 100%;
  position: relative;
  z-index: -2;
}
.bb {
  position: absolute;
  z-index: -1;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
}
.login-title {
  position: absolute;
  top: 15%;
  left: 50%;
  font-size: 23px;
  transform: translateX(-50%);
  color: rgba(245, 245, 245, 0.9);
}
.login-title h2 {
  font-weight: 700;
}
.login-input {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.login-input input {
  border: 0;
  background-color: rgba(200, 200, 200, 0.6);
  caret-color: #fff;
  /*选中后*/
  outline: 0;
  color: #fff;
  /*高度*/
  padding: 15px;
  border-radius: 10px;
  font-size: 13px;
}
.name-b::-webkit-input-placeholder {
  color: #fff;
}
.login-password {
  margin: 20px 0;
}

.btn-login {
  display: block;
  height: 40px;
  width: 100px;
  line-height: 40px;
  margin-left: 25%;
  background-color: rgba(200, 200, 200, 0.6);
  color: #fff;
  border-radius: 10px;
}
</style>
