import { request } from './request'

export function postLogin(username, password) {
  return request({
    method: 'get',
    url: '/user/login',
    params: {
      username,
      password
    }
  })
}

/*根据id查询*/
export function getfindUser(userId) {
  return request({
    url: '/user1/findId',
    params: {
      userId
    }
  })
}
